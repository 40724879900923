import { v4 as uuidv4 } from 'uuid';
function chillHop() {
  return [
    {
      name: ' الاخلاص والفلق',
      cover: 'https://aux4.iconspalace.com/uploads/704184079.png',
      artist: 'عبدالباسط عبدالصمد',
      audio:
        'https://shiavoice.com/media/quran/3bdel_baset/qesar_elsuar/zty1ybxycgtu.mp3',
      color: ['#205950', '#2ab3bf'],
      id: uuidv4(),
      active: true,
    },
    {
      name: ' قصار السور',
      cover: 'https://aux4.iconspalace.com/uploads/704184079.png',
      artist: 'عبدالباسط عبدالصمد',
      audio:
        'https://shiavoice.com/media/quran/3bdel_baset/mutefere8e/rncchghwm9fg.mp3',
      color: ['#EF8EA9', '#ab417f'],
      id: uuidv4(),
      active: false,
    },

    {
      name: 'ما تيسر من قصار السور',
      cover: 'https://aux4.iconspalace.com/uploads/704184079.png',
      artist: 'عبدالباسط عبدالصمد',
      audio:
        'https://shiavoice.com/media/quran/3bdel_baset/mutefere8e/jpzwovynxd36.mp3',
      color: ['#EF8EA9', '#ab417f'],
      id: uuidv4(),
      active: false,

      
    },

    {
      name: 'الاعلى والغاشية',
      cover: 'https://aux4.iconspalace.com/uploads/704184079.png',
      artist: 'عبدالباسط عبدالصمد',
      audio:
        'https://shiavoice.com/media/quran/3bdel_baset/qesar_elsuar/aebpyki3xb18.mp3',
      color: ['#EF8EA9', '#ab417f'],
      id: uuidv4(),
      active: false,
    },
    //ADD MORE HERE
  ];
}

export default chillHop;
